//Copyright by Chaafo pvt ltd


import React from "react";

export default (state, action) => {
  switch (action.type) {
    case "SET_cart":
      return {
        ...state,
        cart: action.payload,
      };
    case "SET_empty":
      return {
        ...state,
        empty: action.payload,
      };
    case "SET_total":
      return {
        ...state,
        total: action.payload,
      };
    case "SET_count":
      return {
        ...state,
        count: action.payload,
      };
    case "SET_Pay":
      return {
        ...state,
        Pay: action.payload,
      };
    case "SET_address":
      return {
        ...state,
        address: action.payload,
      };
    case "SET_merchant":
      return {
        ...state,
        merchant: action.payload,
      };
    case "SET_locate":
      return {
        ...state,
        locate: action.payload,
      };
    case "SET_anlookCart":
      return {
        ...state,
        anlookCart: action.payload,
      };
    default:
      return state;
  }
};
