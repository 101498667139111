//Copyright by Chaafo pvt ltd
//Detailmodal is designed to show item details when clicked on any item name

import React, { useState, useContext, useEffect } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import Icofont from 'react-icofont';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ItemDetail from '../common/ItemDetail';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';
import { GlobalContext } from '../../components/GlobalState';


function Detailmodal(props) {
    var T = localStorage.getItem("tokenn");
    const {MK} = useContext(GlobalContext_);
    const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);

    const [itemdetail, setItemdetail] = useState('');
    const [price, setPrice] = useState([]);
    const [quantity, setQuantity] = useState(props.qty || 0);
    const [newQty, setNewQty]= useState(0);
    const [max] = useState(props.maxValue || 0);
    const [min] = useState(props.minValue || 0);
    const [selectedItem, setSelectedItem] = useState(null); // Stores item_id & size


    var id = localStorage.getItem('id');
    
    //retrieving amount for Qtr. /Half /full  seperately
    const getQuantity = (itemId, size) => {

        const cartItem = cart?.find(item =>item.item_id == itemId && item?.size_words == size)
        const quantityy = cartItem?.qty
        if(quantityy<1){
            props.onHide();
        }
        return quantityy;
    };

    // const getQuantity = getSizeBasedQuantity(props.id, props.size_words);
    //Function to show item details using item id.
    useEffect(() => {
    const itemd = () => {

        if (props.show) {

            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");

            var formdata = new FormData();
            formdata.append("item_id", id);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(urls.ItemDetails+"?merchant_keys=" + MK, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setItemdetail(result.details?.data);
                    setPrice(result.details?.data?.prices)
                })
        }
    }

    itemd();
    }, [props.show,MK])

    const sortCart = (data) => {
        data.sort((a, b) => {
            if (a.sort_id > b.sort_id) {
                return 1;
            }
            else if (b.sort_id > a.sort_id) {
                return -1;
            }
            else {
                return 0;
            }

        })
        return data;
    }
    
        // funtion to decrease item quantity
        const DecreasecartItem = (priceData, commonDetails, index) => {
    
            if (quantity > min) {
                setQuantity(quantity - 1);
                props.getValue({ id: props.id, quantity: quantity - 1 });
                
            }
           
            if (T) {
                var myHeaders = new Headers();
                myHeaders.append("Cookie", "_lang=en");
                var formdata = new FormData();
                formdata.append("item_id", commonDetails.item_id);
                formdata.append("price", priceData.price);
                formdata.append("row", index);
                formdata.append("qty", -1)  // why setting -1? is it to remove item at BE?
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };
                fetch(urls.Add_Delete_Item + "?merchant_keys=" + MK + "&device_id=" + T + "&device_platform=android&transaction_type=pickup&lang=en&_=1644817151280&token=" + T, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 1) {
                            Loadcart();
                            toast.success('Cart Updated', {
                                position: "top-center",
                                autoClose: 400
                            });
                        }
                    })
            }
            else {
                const currentItem = cart?.filter((item) => {
                    return item.item_id === commonDetails.item_id && priceData.size_id === priceData.size_id;
                });
                // item is present
                const newCart = cart?.filter((item) => {
                    return item.item_id !== commonDetails.item_id ? true : item.size_id !== priceData.size_id;
                });
                if (currentItem[0].qty > 0) {
                    const itemToAdd = { ...currentItem[0], qty: currentItem[0].qty - 1 };
                    newCart?.push(itemToAdd);
                }
                localStorage.setItem('cart', JSON.stringify(newCart));
                toast.success('cart updated', {
                    position: "top-center",
                    autoClose: 400
                });
                Loadcart();
                
            }
        }
    
        const addtocart = (priceData, commonDetails) => {
            // Increment quantity
            if (quantity < max) {
                setQuantity(quantity + 1);
                props.getValue({ id: commonDetails.item_id, quantity: quantity + 1 });
            }
    
            if (T) {
                // Prepare headers and form data for the fetch request
                const myHeaders = new Headers();
                myHeaders.append("Cookie", "_lang=en");
                const formdata = new FormData();
                formdata.append("item_id", commonDetails.item_id);
                formdata.append("price", priceData.price);
                formdata.append("qty", "1");
                formdata.append("size_words", priceData.size);
                formdata.append("dish_list", commonDetails.dish_list[0]);
    
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };
    
                // Make the fetch request
                fetch(`${urls.Add_Delete_Item}?merchant_keys=${MK}&device_id=${T}&device_platform=web&transaction_type=pickup&lang=en&_=1644817151280&token=${T}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 1) {
                            toast.success(result.msg, {
                                position: "top-center",
                                autoClose: 400
                            });
                            Loadcart();
                        } else {
                            // Handle errors here
                            toast.error('Failed to add item to cart', {
                                position: "top-center",
                                autoClose: 400
                            });
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        toast.error('Error adding item to cart', {
                            position: "top-center",
                            autoClose: 400
                        });
                    });
            } else {
                // Handle cart management locally
                const currentItem = cart?.find(item => item.item_id === commonDetails.item_id && item.size_id === priceData.size_id);
    
                if (!currentItem) {
                    // Item not in cart
                    const newItemToAdd = {
                        item_id: commonDetails.item_id,
                        size_id: priceData.size_id,
                        photo: commonDetails.photo,
                        item_name: commonDetails.item_name,
                        price: priceData.price,
                        qty: 1,
                        // category_id: props.category_id,
                        sort_id: (cart?.length || 0) + 1,
                        cart_count: cart?.length || 0,
                        size_words: priceData.size
                    };
    
                    const newCart = [...(cart || []), newItemToAdd];
                    localStorage.setItem('cart', JSON.stringify(sortCart(newCart)));
                    toast.success('Item added to cart', {
                        position: "top-center",
                        autoClose: 400
                    });
                } else {
                    // Item already in cart
                    const newCart = (cart || []).map(item =>
                        item.item_id === commonDetails.item_id && item.size_id === priceData.size_id
                            ? { ...item, qty: item.qty + 1 }
                            : item
                    );
    
                    localStorage.setItem('cart', JSON.stringify(sortCart(newCart)));
                    toast.success('Item quantity updated', {
                        position: "top-center",
                        autoClose: 400
                    });
                }
                setSelectedItem({ itemId: commonDetails.item_id, size: priceData.size });

                Loadcart();
            }
        };
        //user click to show item details and add something to cart, this function renders the cart on the same time
        //if user is logged in, that means token is there, therefore if case will be executed. Otherwise, else case will be executed.
        async function Loadcart() {
            return new Promise((resolve, reject) => {
                try {
                    if (T) {
                        var myHeaders = new Headers();
                        myHeaders.append("Cookie", "_lang=en");
            
                        var formdata = new FormData();
                        formdata.append("token", T);
                        formdata.append("device_id", T);
            
                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        }
                        fetch(urls.LoadCart + "?merchant_keys=" + MK, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 1) {
                                    setCart(result.details.data.item);
                                    setTotal(result.details.data.total);
                                    setCount(result.details.cart_details);
                                    setEmpty('');
                                    setPay(result.details);
                                    return result.details.data.item;  // Return server cart data
                                }
                            });
                    }
                    else {
                        const raw = localStorage.getItem('cart');
                        const cartFetchedFromLocalStorage = JSON.parse(raw);
                        setCart(sortCart(cartFetchedFromLocalStorage));
            
                        const tRaw = localStorage.getItem('total');
                        const totalFetchedFromLocalStorage = JSON.parse(tRaw);
                        setTotal(totalFetchedFromLocalStorage);
            
                        const cRaw = localStorage.getItem('Count');
                        const countFetchedFromLocalStorage = JSON.parse(cRaw);
                        setCount(countFetchedFromLocalStorage);
            
                        const eRaw = localStorage.getItem('empty');
                        const EmptyFetchedFromLocalStorage = JSON.parse(eRaw);
                        setEmpty(EmptyFetchedFromLocalStorage);
            
                        const pRaw = localStorage.getItem('pay');
                        const payFetchedFromLocalStorage = JSON.parse(pRaw);
                        setPay(payFetchedFromLocalStorage);
            
                        return cartFetchedFromLocalStorage; // Return local cart data
                    }

                    // Simulating async operation with setTimeout
                    setTimeout(() => {
                        resolve(); // Resolve when done
                    }, 500); // Adjust time if needed
                } catch (error) {
                    reject(error);
                }
            });
            
        }
useEffect(() => {
    if (price.length === 1 && props.quantity === 0) {
      const singlePriceObj = price[0];
      addtocart(singlePriceObj, itemdetail);
    }
  }, [price]);
        
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            centered
        >
            <Modal.Header closeButton={true} className="d-flex gap-2">
          <img src={itemdetail?.dish_list} alt='status' loading="lazy" style={{height: '1.5rem', width: 'auto'}}/>
                <Modal.Title as='h5' id="add-address">{itemdetail.item_name} </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Image style={{ width: '245px', height: '200px' }} src={itemdetail.photo} alt={itemdetail.item_name} loading="lazy"/>
                    {(price?.map((obj , index)=>
                        
                        <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm mb-2">
                                    <div className="list-card-body p-3 d-flex justify-content-between align-items-center">
                                        {obj.size ? (
                                            <>
                                                <div className="text-gray mb-0">
                                                    {(obj.discount_price > 0) ? (<p>
                                                        <del>₹{obj.price} </del>₹{obj.discount_price} </p>)
                                                        :
                                                        <p className="text-gray time mb-0"><b>{obj.size}</b><span>{' '}</span>₹{obj.price}</p>
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        getQuantity(itemdetail.item_id, obj.size)>0
                                                        ?
                                                            <span className="count-number float-right">
                                                                <Button variant="outline-secondary" 
                                                                onClick={()=>DecreasecartItem(obj, itemdetail, index)}
                                                                 className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
                                                                <input className="count-number-input p-0" type="text" 
                                                                value={getQuantity(itemdetail.item_id, obj.size)} 
                                                                readOnly />
                                                                <Button variant="outline-secondary " 
                                                                onClick={()=>addtocart(obj, itemdetail)} 
                                                                className="btn-sm right inc" > <Icofont icon="icofont-plus" /> </Button>
                                                            </span>
                                                            :
                                                            <Button variant='outline-secondary' 
                                                            onClick={()=>addtocart(obj, itemdetail)}
                                                             size="sm">ADD</Button>
                                                    } 
                                                </div>
                                            </>
                                        )
                                            : ''
                                        }
                                    </div>
                                </div>
                    ))}
                </Modal.Body>
        </Modal>
    );
}

export default Detailmodal;