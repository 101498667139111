//Copyright by Chaafo pvt ltd
//Checkout is designed as whole checkout page

import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { GlobalContext } from "../../components/GlobalState";
import GlobalContext_ from "../../GlobalContext";
import { RESTAURANT, MERCHANTKEY } from "../../App";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useRazorpay from "react-razorpay";
import { urls } from "../URLs";
import WhatsappOrderSummaryCard from "./WhatsappOrderSummaryCard";
import { BiArrowBack } from "react-icons/bi";
import { Helmet } from "react-helmet";

import { CardColors, SiteColors } from "../../constants/colorTheme";

var PAYTM_FB_LOGIN_URL =
  "https://chaafo.com/protected/vendor/PaytmKit/pgRedirect2.php?";

function WhatsappCheckout() {
  const params = new URLSearchParams(window.location.search);
  const [error, setError] = useState(null);

  const { anlookCart } = useContext(GlobalContext);
  const [anlookOrderData, setAnlookOrderData] = useState([]);
  // const MK = 'ba7e4279f77945dcd9cf7a4e7f0345e8';
  var T = localStorage.getItem("tokenn");
  var del_id = localStorage.getItem("del_id");
  const { restaurantinfo, MK } = useContext(GlobalContext_);
  const history = useHistory();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSetDelivery, setSetDelivery] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [key1, setKey1] = useState("");
  const [message, setMessage] = useState(null);
  const [service, setService] = useState("");
  const [loading, setLoading] = useState(false);
  const [transactiontype, setTransactiontype] = useState("");
  const [vouchername, setVouchername] = useState("");
  const [points, setPoints] = useState(0);
  const [method, setMethod] = useState(" ");
  const [Mobile, setMobile] = useState("");
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");

  const [show, setShow] = useState(false);

  const showDropdown = (e) => {
    setShow(!show);
  };

  const hideDropdown = (e) => {
    setShow(false);
  };

  toast.configure();
  const {
    cart,
    setCart,
    setTotal,
    total,
    setCount,
    setEmpty,
    Pay,
    setPay,
    address,
    setAddress,
  } = useContext(GlobalContext);

  const Razorpay = useRazorpay();
  //Function to fetch payment method lists
  async function PaymentMethod() {
    const response = await fetch(
      urls.PaymentList + "?merchant_keys=" + MK + "&token=" + T
    );
    const result = await response.json();
    if (result.code == 1) {
      setMethod(result.details.data);
    }
  }

  //Function to show all saved addresses in address booklist
  async function Address() {
    const response = await fetch(
      urls.AddressList + "?&merchant_keys=" + MK + "&token=" + T
    );
    const data = await response.json();
    if (data.code == 1) {
      setAddress(data.details.data);
    }
  }

  useEffect(() => {
    if (address == null) {
      Address();
    }
  }, []);

  //function to show services list i.e. delivery, dine in or pick up
  const ServiceList = () => {
    fetch(urls.ServicesList + "?merchant_keys=" + MK)
      .then((response) => response.json())
      .then((result) => {
        if (result.code == 1) {
          setService(result.details.data);
          Loadcart();
          toast.success("Selected", {
            position: "top-center",
            autoClose: 400,
          });
        }
      });
  };

  //function to show services list i.e. delivery, dine in or pick up
  const ServiceList1 = () => {
    fetch(urls.ServicesList + "?merchant_keys=" + MK)
      .then((response) => response.json())
      .then((result) => {
        if (result.code == 1) {
          setService(result.details.data);
          Loadcart();
        }
      });
  };

  //Function to fetch user information
  const GetUserInfo = () => {
    var Token = localStorage.getItem("tokenn");
    fetch(
      urls.UserInfo +
        "?merchant_keys=" +
        MK +
        "&device_id=" +
        T +
        "&device_platform=web&token=" +
        Token +
        "&lang=en&_=1648619760308"
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code == 1) {
          setName(result.details.data.name);
          setEmail(result.details.data.email_address);
          setMobile(result.details.data.contact_phone);
        }
      });
  };

  //Function to load all the activities happening on checkout page in cart
  async function Loadcart() {
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "_lang=en");

    var formdata = new FormData();
    formdata.append("device_id", T);
    formdata.append("token", T);
    formdata.append("transaction_type", transactiontype);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      urls.LoadCart +
        "?merchant_keys=" +
        MK +
        "&transaction_type=" +
        transactiontype +
        "&token=" +
        T,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 1) {
          setCart(result.details.data.item);
          setTotal(result.details.data.total);
          setCount(result.details.cart_details);
          setEmpty("");
          setPay(result.details);
        }
      });
  }

  useEffect(() => {
    Loadcart();
    PaymentMethod();
  }, [MK]);

  const hideDeleteModal = () => setShowDeleteModal(false);
  const hideAddressModal = () => setShowAddressModal(false);
  const hideEditModal = () => setShowEditModal(false);
  const hideDeliveryModal = () => setSetDelivery(false);
  const getQty = ({ id, quantity }) => {};

  //Function to apply voucher in cart
  const VOUCHER = () => {
    fetch(
      urls.ApplyVoucher +
        "?merchant_keys=" +
        MK +
        "&voucher_name=" +
        key1 +
        "&device_id=" +
        T
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code == 1) {
          Loadcart();
          setMessage(result.msg);
          setVouchername(result.get.voucher_name);
          toast.success("Voucher applied!", {
            position: "top-center",
            autoClose: 400,
          });
        } else {
          toast.error(result.msg, {
            position: "top-center",
            autoClose: 400,
          });
        }
      });
  };

  //Function to remove voucher from cart
  const REMOVE = () => {
    fetch(urls.RemoveVoucher + "?merchant_keys=" + MK + "&device_id=" + T)
      .then((response) => response.json())
      .then((result) => {
        if (result.code == 1) {
          toast.error("Voucher Removed", {
            position: "top-center",
            autoClose: 400,
          });
          Loadcart();
        }
      });
  };

  //Function to apply or redeem loyalty points in cart
  const Points = () => {
    fetch(
      urls.ApplyPointsUrl +
        "?merchant_keys=" +
        MK +
        "&device_id=" +
        T +
        "&token=" +
        T +
        "&points=" +
        points
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code == 1) {
          toast.success(result.msg, {
            position: "top-center",
            autoClose: 400,
          });
          Loadcart();
        } else {
          toast.error(result.msg, {
            position: "top-center",
            autoClose: 700,
          });
        }
      });
  };

  //Function to remove points from cart
  const removepoints = () => {
    fetch(
      urls.RemovePointsUrl +
        "?merchant_keys=" +
        MK +
        "&device_id=" +
        T +
        "&token=" +
        T
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code == 1) {
          toast.success("Points removed", {
            position: "top-center",
            autoClose: 400,
          });
          Loadcart();
        }
      });
  };

  const placeOrder = async () => {
    try {
      const response = await fetch(
        "https://payin.shreedevs.com/api/Order/placeOrder",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
          body: JSON.stringify({
            orderId: params.get("orderid"),
            userid: params.get("userid"),
            mobile: params.get("mobile"),
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      window.location.href = "https://wa.me/";
    } catch (err) {
      setError(err.message);
    }
  };

  // useEffect(() => {
  //   // Define the API call
  //   const fetchOrderData = async () => {
  //     try {
  //       const response = await fetch(
  //         "https://payin.shreedevs.com/api/Order/getReceiptPrintDataV2",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({ orderid: params.get("orderid") }),
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error(`Error: ${response.statusText}`);
  //       }

  //       const data = await response.json();
  //       setAnlookOrderData(data.response[0].items);
  //     } catch (err) {
  //       setError(err.message);
  //     }
  //   };

  //   fetchOrderData();
  // }, []);

  return (
    <GlobalContext.Consumer>
      {(state) => (
        <div style={{ margin: "1rem" }} className=" gap-2 d-flex flex-column">
          <Helmet>
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>

          <h3>Order Summary</h3>
          <WhatsappOrderSummaryCard
            cart={anlookOrderData}
            state={state}
            key1={key1}
            setKey1={setKey1}
            getQty={getQty}
            VOUCHER={VOUCHER}
            REMOVE={REMOVE}
            setPoints={setPoints}
            Points={Points}
            removepoints={removepoints}
            service={service}
            ServiceList1={ServiceList1}
            setTransactiontype={setTransactiontype}
            ServiceList={ServiceList}
            setShowEditModal={setShowEditModal}
            setSetDelivery={setSetDelivery}
            pageName="checkout"
          />
          <div
            className="py-2"
            style={{
              position: "fixed",
              bottom: "0",
              width: "93%",
              background: "white",
            }}
          >
            <Link
              to={`/anlook/order?mobile=${params.get(
                "mobile"
              )}&userid=${params.get("userid")}&orderid=${params.get(
                "orderid"
              )}`}
              style={{
                border: `2px solid ${CardColors.buttonPrimary}`,
                color: anlookCart.length >= 1 ? CardColors.buttonPrimary : 'white',
                
                background: !anlookCart.length >= 1 ? CardColors.buttonPrimary : 'none' 
              }}
              className="btn btn-block btn-lg gap-2 d-flex align-items-center justify-content-center"
            >
              <BiArrowBack />
              {anlookCart.length >= 1 ?'Add More Items': 'Add Items'}
              
            </Link>
            {anlookCart.length >= 1 ? (
              <div
                style={{ background: CardColors.buttonPrimary }}
                className="btn btn-block btn-lg text-white"
                onClick={() => placeOrder(state)}
              >
                Place Order
              </div>
            ) : null}
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
}

export default React.memo(WhatsappCheckout);
