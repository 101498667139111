//Copyright by Chaafo pvt ltd
//Making global state of those api's which are to be used in whole website

import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

var T = localStorage.getItem("tokenn");

//Setting initial states for things we are getting from local storage

const initialCartState = () => {
  const raw = localStorage.getItem('cart');
  const cartFetchedFromLocalStorage = JSON.parse(raw);
  return cartFetchedFromLocalStorage ? cartFetchedFromLocalStorage : [];
}
const initialtotalState = () => {
  const tRaw = localStorage.getItem('total');
  const totalFetchedFromLocalStorage = JSON.parse(tRaw);
  return totalFetchedFromLocalStorage ? totalFetchedFromLocalStorage : [];
}

const initialcountState = () => {
  const cRaw = localStorage.getItem('Count');
  const countFetchedFromLocalStorage = JSON.parse(cRaw);
  return countFetchedFromLocalStorage ? countFetchedFromLocalStorage : [];
}

const initialemptyState = () => {
  const eRaw = localStorage.getItem('empty');
  const EmptyFetchedFromLocalStorage = JSON.parse(eRaw);
  return EmptyFetchedFromLocalStorage ? EmptyFetchedFromLocalStorage : [];
}

const initialpayState = () => {
  const pRaw = localStorage.getItem('pay');
  const payFetchedFromLocalStorage = JSON.parse(pRaw);
  return payFetchedFromLocalStorage ? payFetchedFromLocalStorage : [];
}

const initialAnlookCartState = () => {
  const raw = localStorage.getItem('anlookCart');
  const anlookCartFetchedFromLocalStorage = JSON.parse(raw);
  return anlookCartFetchedFromLocalStorage ? anlookCartFetchedFromLocalStorage : [];
}

const initialState = {
  empty: initialemptyState(),
  cart: initialCartState(),
  total: initialtotalState(),
  count: initialcountState(),
  Pay: initialpayState(),
  address: null,
  merchant: '',
  locate: false,
  anlookCart: initialAnlookCartState(),
};


const initialState1 = {
  empty: null,
  cart: null,
  total: '',
  count: null,
  Pay: '',
  address: null,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions for changing state
  function setCart(cart) {
    dispatch({
      type: "SET_cart",
      payload: cart,
    });
  }
  function setEmpty(empty) {
    dispatch({
      type: "SET_empty",
      payload: empty,
    });
  }
  function setTotal(total) {
    dispatch({
      type: "SET_total",
      payload: total,
    });
  }
  function setCount(count) {
    dispatch({
      type: "SET_count",
      payload: count,
    });
  }
  function setPay(Pay) {
    dispatch({
      type: "SET_Pay",
      payload: Pay,
    });
  }
  function setAddress(address) {
    dispatch({
      type: "SET_address",
      payload: address,
    });
  }
  function setMerchant(merchant) {
    dispatch({
      type: "SET_merchant",
      payload: merchant,
    });
  }

  function setLocate(locate) {
    dispatch({
      type: "SET_locate",
      payload: locate,
    });
  }

  function setAnlookCart(anlookCart) {
    dispatch({
      type: "SET_anlookCart",
      payload: anlookCart,
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        cart: state.cart,
        empty: state.empty,
        total: state.total,
        count: state.count,
        Pay: state.Pay,
        address: state.address,
        merchant: state.merchant,
        locate: state.locate,
        anlookCart: state.anlookCart,

        setCart,
        setEmpty,
        setTotal,
        setCount,
        setPay,
        setAddress,
        setMerchant,
        setLocate,
        setAnlookCart,

      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
