import React, { useContext } from 'react';
import GlobalContext_ from '../GlobalContext';

import CardItem from './common/CardItem';
import SectionHeading from './common/SectionHeading';
import { Row, Col, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel3';
import { GlobalContext } from './GlobalState';
import { SiteColors } from '../constants/colorTheme';

const Spotlight=()=>{
    
	const {top_picks, spotlight, category, category_d} = useContext(GlobalContext_);

    const getQty = ({ id, quantity }) => {
	}
	
    const options = {
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
            1200: {
                items: 4,
            },
        },
    
        lazyLoad: true,
        pagination: false.toString(),
        loop: true,
        dots: false,
        autoPlay: 2000,
        nav: true,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]
    }

  return (
    <GlobalContext.Consumer>
		{
				(state) => (
		<>
				{spotlight ?
					<div>
						<section className="section pt-5 pb-5 products-section" style={{background: SiteColors.primary}}>
							<Container>
								<SectionHeading
									heading='Spotlight'
								/>
								<Row>
									<Col md={12}>
										<OwlCarousel nav loop {...options} className="owl-carousel-four owl-theme">

											{(spotlight?.map((Spotlight,Index) =>
												<div className="item">
													<CardItem
													
													qty={Number(state?.cart?.find(cartItem => cartItem.item_id == Spotlight.item_id)?.qty ?? 0)}
													category_id={Spotlight?.cat_id || 0}
														id={Spotlight?.item_id}
														title={Spotlight?.item_name}
														subTitle={Spotlight?.item_description || 'Indian • American • Italian'}
														imageAlt={Spotlight?.photo}
														image={Spotlight?.photo}
														imageClass='BTS item-img'
														// linkUrl='detail'
														// offerText='65% off | Use Coupon OSAHAN50'
														// time='20–25 min'
														price={Number(Spotlight?.prices.price)}
														size={Spotlight?.prices.size}
														discountprice={Spotlight?.prices.discount_price}
														priceUnit='₹ '
														vegNonvegTag={Spotlight.icon_dish[0]}
														// showPromoted={false}
														promotedVariant='dark'
														favIcoIconColor='text-danger'
														getValue={getQty}
														Index= {Index}
														// category_id={category || 'null'}
														// rating='3.1 (300+)'
													/>
												</div>
											))}

										</OwlCarousel>
									</Col>
								</Row>
							</Container>
						</section>
					</div> :
					<div> </div>
				}
				</>
)
}
			</GlobalContext.Consumer>
  )
}

export default Spotlight