//Copyright by Chaafo pvt ltd
//BestSeller file is designed to show recommended items
//Add button, increase item or decrease item design is made here 

import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Image, Badge, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import Detailmodal from '../modals/Detailmodal';
import { GlobalContext } from '../../components/GlobalState';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function BestSeller(props) {
  const { MK } = useContext(GlobalContext_);

  const { qty, size } = props;

  var T = localStorage.getItem("tokenn");
  const [quantity, setQuantity] = useState(qty);
  const [max] = useState(props.maxValue);
  const [min] = useState(props.minValue || 0);
  const [showDetailmodal, setShowDetailmodal] = useState(false);
  const [price, setPrice] = useState([]);

  const hideDetailmodal = () => setShowDetailmodal(false);

  const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);

  useEffect(() => {
    setQuantity(qty || 0);
  }, [qty])

  const sortCart = (data) => {
    data.sort((a, b) => {
      if (a.sort_id > b.sort_id) {
        return 1;
      }
      else if (b.sort_id > a.sort_id) {
        return -1;
      }
      else {
        return 0;
      }

    })
    return data;
  }

  toast.configure();

  //retrieving the total  Qtr. + Half + full 
  const getTotalQuantity = (itemId) => {
    return cart?.filter(item => item.item_id == itemId)
      .reduce((total, item) => total + item.qty, 0);
  };

  const totalQuantity = getTotalQuantity(props.id);

  // funtion to load item details  
  const Itemdetails = () => {

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "_lang=en");

    var formdata = new FormData();
    formdata.append("item_id", props.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(urls.ItemDetails + "?merchant_keys=" + MK, requestOptions)
      .then(response => response.json())
      .then(result => { setPrice(result.details.data.prices) })
    localStorage.setItem("id", props.id);
  }
  
  return (
    <div className="list-card bg-white  rounded overflow-hidden position-relative" style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
      <Detailmodal show={showDetailmodal} onHide={hideDetailmodal} quantity={quantity} />
      <div className="list-card-image">
        {props.rating ? (
          <div className="star position-absolute">
            <Badge variant="success">
              <Icofont icon='star' /> {props.rating}
            </Badge>
          </div>
        )
          : ""
        }
        <Link onClick={() => setShowDetailmodal(true)}>
          <Image 
          // onClick={()=>Itemdetails()} 
          src={props.image} 
          style={{ height: '10rem', width: '100%' }} 
          alt={props.imageAlt} loading="lazy"/>
        </Link>
      </div>
      <div className="p-3 d-flex flex-column" style={{ height: '100%' }} >
        <div style={{ height: '6.5rem' }}>
          <div className='d-flex gap-2'>
            <img src={props.vegNonvegTag} alt='' loading='lazy' style={{height: '1.2rem', width: 'auto'}}/>
            <h6 className="text-black" >{props.title}</h6>
          </div>
          <p
            className="text-gray mb-0 text-truncate-2-lines"
            title={props.subTitle}  // Show full subtitle in tooltip on hover
          >
            {props.subTitle || ''}
          </p>

        </div>
        <div className='d-flex' style={{ justifyContent: 'space-between' }}>
          <div className="text-gray time mb-0">
            {
              (props.discountprice > 0) ?
                <span className="text-gray time mb-0">
                  <del>{props.priceUnit}{props.price} </del>{props.priceUnit}{props.discountprice}
                </span>
                :
                <span className="text-gray time mb-0">{props.priceUnit}{props.price}</span>
            }
            {(props.isNew) ? (<Badge variant="success" className='ml-1'>NEW</Badge>) : ""}
          </div>

          {(props.price) ?
            quantity === 0 ?
              <span className="float-right" onClick={() => setShowDetailmodal(true)}>
                <Button variant='outline-secondary' onClick={()=>Itemdetails()} size="sm">ADD</Button>
              </span>
              :
              <span className="count-number float-right" onClick={() => setShowDetailmodal(true)}>
                <Button variant="outline-secondary" onClick={()=>Itemdetails()} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
                <input className="count-number-input" onClick={()=>Itemdetails()} type="text" value={totalQuantity} readOnly />
                <Button variant="outline-secondary" onClick={()=>Itemdetails()} className="btn-sm right inc"> <Icofont icon="icofont-plus" /> </Button>
              </span>
            : ''
          }
        </div>
      </div>
    </div>
  );
}


export default BestSeller;

