//Copyright by Chaafo pvt ltd
//

import React, { Component, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import Icofont from "react-icofont";
import { GlobalContext } from "../../../components/GlobalState";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MERCHANTKEY } from "../../../App";
import { urls } from "../../URLs";
import GlobalContext_ from "../../../GlobalContext";
import { RiDeleteBin6Line } from "react-icons/ri";

function CheckoutItemAnlook(props) {
  const [anlookOrderData, setAnlookOrderData] = useState([]);
  const [error, setError] = useState(null);
  const { qty, size, item } = props;
  const { MK, zeroItems } = useContext(GlobalContext_);
  var T = localStorage.getItem("tokenn");
  const [quantity, setQuantity] = useState(qty);
  const [max] = useState(12);
  const [min] = useState(props.minValue || 0);
  const params = new URLSearchParams(window.location.search);

  const { cart, count, setCart, setTotal, setCount, setEmpty, setPay } =
    useContext(GlobalContext);

  const sortCart = (data) => {
    data.sort((a, b) => {
      if (a.sort_id > b.sort_id) {
        return 1;
      } else if (b.sort_id > a.sort_id) {
        return -1;
      } else {
        return 0;
      }
    });
    return data;
  };
  useEffect(() => {
    setQuantity(qty || 0);
  }, [qty]);
  toast.configure();

  // funtion to decrease item quantity
  const DecreasecartItem = async (
    size_id,
    title,
    price,
    qty,
    size,
    item_id
  ) => {
    const itemName = `${title} ( ${size} )`;
    const orderId = params.get("orderid");
    const userId = params.get("userid");
    const mobile = params.get("mobile");
    const name = params.get("name");
    const raw = JSON.stringify({
      userid: userId,
      currency: "INR",
      custMobile: mobile,
      custName: name,
      orderId: orderId,
      items: [
        {
          productId: item_id,
          sizeId: size_id,
          actualName: title,
          productName: itemName,
          quantity: qty,
          itemPrice: price,
          sizeWords: size,
        },
      ],
    });

    try {
      const response = await fetch(
        "https://payin.shreedevs.com/api/Order/removeFromCart",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
          body: raw,
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      props.refresh();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addtocart = async (size_id, icon, title, price, qty, size, item_id) => {
    const itemName = title + "(" + size + ")";

    const orderId = params.get("orderid");
    const userId = params.get("userid");
    const mobile = params.get("mobile");
    const name = params.get("name");
    const raw = JSON.stringify({
      userid: userId,
      currency: "INR",
      custMobile: mobile,
      custName: name,
      orderId: orderId,
      items: [
        {
          actualName: title,
          productId: item_id,
          sizeId: size_id,
          productName: title,
          quantity: qty,
          itemPrice: price,
          itemImage: icon[0],
          sizeWords: size,
        },
      ],
    });

    try {
      const response = await fetch(
        "https://payin.shreedevs.com/api/Order/addToCart",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
          body: raw,
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      if (props.refresh) {
        props.refresh();
      }
      // setAnlookOrderData(data.response[0].items);
    } catch (err) {
      // setError(err.message);
    }
  };
  const removeFromCart= async (
    size_id,
    item_id
  ) => {
    const orderId = params.get("orderid");
    const userId = params.get("userid");
    const raw = JSON.stringify({
      userid: userId,
      orderId: orderId,
      itemId: item_id,
      sizeId: size_id,
    });

    try {
      const response = await fetch(
        "https://payin.shreedevs.com/api/Order/removeItemFromCart",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
          body: raw,
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      props.refresh();
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div
      className=" border-bottom justify-content-between d-flex flex-wrap align-items-center gap-2 p-2"
      style={{}}
    >
      <div className="d-flex align-items-center justify-content-center gap-1 ">
        <div className="d-flex">
          <img
            src={props.vegNonvegTag}
            alt=""
            loading="lazy"
            style={{ height: "1rem", width: "auto" }}
          />
        </div>
       
          <p className=" m-0 p-0 text-black align-items-center justify-content-center d-flex">
            {props.itemName}
          </p>
      </div>
      <div className="d-flex align-items-center gap-2" style={{marginLeft: 'auto'}}>
        <p className="text-black mb-0">
          {props.priceUnit}
          {Number(item.item_price) * item.quantity}
        </p>
        <span className="count-number float-right">
          <Button
            variant="outline-secondary"
            onClick={() =>
              DecreasecartItem(
                props.sizeId,
                props?.itemName,
                props.price,
                1,
                props.sizeWords,
                props?.id
              )
            }
            className="btn-sm left dec"
          >
            {" "}
            <Icofont icon="minus" />{" "}
          </Button>

          <input
            className="count-number-input p-0"
            type="text"
            value={Math.abs(qty)}
            readOnly
          />
          <Button
            variant="outline-secondary "
            onClick={() =>
              addtocart(
                props.sizeId,
                props.vegNonvegTag,
                props?.actualName,
                props.price,
                1,
                props.sizeWords,
                props?.id
              )
            }
            className="btn-sm right inc"
          >
            {" "}
            <Icofont icon="icofont-plus" />{" "}
          </Button>
        </span>
        <Button
          variant="outline-secondary "
          onClick={() =>
            removeFromCart(
              props.sizeId,
              props?.id
            )
          }
          className=" border-0 fs-6 align-items-center d-flex p-0"
        >
          {" "}
          <Icofont icon="bin" />
        </Button>
      </div>
    </div>
  );
}

CheckoutItemAnlook.propTypes = {
  size_id: PropTypes.number.isRequired,
  itemName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  discounted_price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  qty: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  getValue: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  category_id: PropTypes.number.isRequired,
};
CheckoutItemAnlook.defaultProps = {
  show: true,
  priceUnit: "$",
};

export default React.memo(CheckoutItemAnlook);
