//Copyright by Chaafo pvt ltd
//MayLikeItems is designed to show searced items and items in carousel


import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image, Button, Badge } from 'react-bootstrap';
import Icofont from 'react-icofont';
import { GlobalContext } from '../../components/GlobalState';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Shimmer from "react-shimmer-effect";
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';
import DetailModalAnlook from './innerComponents/DetailModalAnlook';


function ItemSuggestions(props) {
  const { MK } = useContext(GlobalContext_);

  const { qty, size, cart } = props;

  var T = localStorage.getItem("tokenn");
  const [quantity, setQuantity] = useState(qty);
  const [max] = useState(props.maxValue);
  const [min] = useState(props.minValue || 0);
  const [showDetailmodal, setShowDetailmodal] = useState(false);
  const [price, setPrice] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const hideDetailmodal = () => setShowDetailmodal(false);

  const { setCart, setTotal, setCount, setEmpty, setPay } =
    useContext(GlobalContext);

  useEffect(() => {
    setQuantity(qty || 0);
  }, [qty]);

  const sortCart = (data) => {
    data.sort((a, b) => {
      if (a.sort_id > b.sort_id) {
        return 1;
      } else if (b.sort_id > a.sort_id) {
        return -1;
      } else {
        return 0;
      }
    });
    return data;
  };

  toast.configure();

  //retrieving the total  Qtr. + Half + full
  const getTotalQuantity = (itemId) => {
    
    return cart
      ?.filter((item) => item.item_id == itemId)
      .reduce((total, item) => total + Number(item.quantity), 0);
  };
  // console.table(cart);
  console.table(props.id);
  const totalQuantity = getTotalQuantity(props.id);

  // funtion to load item details
  const Itemdetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "_lang=en");

    var formdata = new FormData();
    formdata.append("item_id", props.id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(urls.ItemDetails + "?merchant_keys=" + MK, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPrice(result.details.data.prices);
      });
    localStorage.setItem("id", props.id);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={"position-relative list-card rounded " + props.boxClass} style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', border: 'none' }}>
      <DetailModalAnlook show={showDetailmodal} onHide={hideDetailmodal} quantity={quantity} cart={cart} refresh={props.refresh}/>
      <div className="list-card-image">
        {props.rating ? (
          <div className="star position-absolute">
            <Badge variant="success">
              <Icofont icon='star' /> {props.rating}
            </Badge>
          </div>
        )
          : ""
        }
        {props.showPromoted ? (
          <div className="member-plan position-absolute">
            <Badge variant={props.promotedVariant}>Promoted</Badge>
          </div>
        )
          : ""
        }
          <Image src={props.image} className={props.imageClass} style={{ height: '10rem', width: '100%' }} alt={props.imageAlt} />
      </div>
      <div className="p-3 d-flex flex-column" style={{ height: '100%' }} >
        <div  className='text-start d-flex flex-column'>
          <div className='d-flex gap-2'>
            <img src={props.vegNonvegTag} alt='' loading='lazy' style={{height: '1.2rem', width: 'auto'}}/>
            <h6 className="text-black p-0" >{props.title}</h6>
          </div>
          <p className="text-gray mb-0 py-1" style={{cursor: 'pointer'}} title={props.subTitle}>
            {isExpanded ? props.subTitle : `${props.subTitle?.slice(0, 50)}...`}
            {props.subTitle && props.subTitle.length > 50 && (
              <span className="text-primary cursor-pointer" onClick={toggleExpand}>
                {isExpanded ? " See Less" : " See More"}
              </span>
            )}
          </p>
        </div>
        <div className='d-flex' style={{ justifyContent: 'space-between' }}>
          <div className="text-gray time mb-0" >
            {
              (props.discountprice > 0) ?
                <span className="text-black time mb-0" style={{ fontSize: '1rem' }}>
                  <del>₹{props.price} </del>₹{props.discountprice}
                </span>
                :
                <p className="text-black time mb-0" style={{ fontSize: '1rem' }}>₹{props.price}</p>
            }
            {(props.isNew) ? (<Badge variant="success" className='ml-1'>NEW</Badge>) : ""}
          </div>
          {(props.price) ?
            totalQuantity > 0 ?
              <span className="count-number float-right">
                <Button variant="outline-secondary" onClick={() => setShowDetailmodal(true)} className="btn-sm left dec btn"> <Icofont icon="minus" /> </Button>
                <input className="count-number-input" type="text" value={totalQuantity} readOnly />
                <Button variant="outline-secondary" onClick={() => setShowDetailmodal(true)} className="btn-sm right inc btn"> <Icofont icon="icofont-plus" /> </Button>
              </span>
              :
              <span
                       className="float-right"
                       onClick={() => setShowDetailmodal(true)}
                     >
                <Button variant='outline-secondary'  onClick={Itemdetails} size="sm">ADD</Button>
              </span>
            : ''
          }
        </div>
      </div>
    </div>
  );

}

ItemSuggestions.propTypes = {
  onAdd: PropTypes.func,
  qty: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  imageAlt: PropTypes.string,
  boxClass: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  getValue: PropTypes.func.isRequired,
  category_id: PropTypes.number.isRequired
};

ItemSuggestions.defaultProps = {
  imageAlt: '',
  image: '',
  imageClass: '',
  boxClass: 'mall-category-item',
  title: '',
  price: '',
}

export default ItemSuggestions;
