// Copyright by Chaafo pvt ltd
// Login file 

import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import FontAwesome from './common/FontAwesome';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FacebookLogin from 'react-facebook-login';
import { urls } from './URLs';
import GlobalContext_ from '../GlobalContext';
import { Helmet } from 'react-helmet';
import seoData from '../seo.json';

function Login() {
	const { MK, restaurantinfo } = useContext(GlobalContext_);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [merchant, setMerchant] = useState('');
	const history = useHistory();
	const [passwordShown, setPasswordShown] = useState(false);

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();  // Prevent the default form submission behavior
			login();
		}
	}

	const metaData = seoData.data[0].pages.find(page => page.basic_settings.page_name == 'login')

    const pageTitle = metaData.basic_settings?.tittle
    const basicId = metaData.basic_settings?.basic_id
    const mobileView = metaData.basic_settings?.mobile_view
    const metaDescription = metaData.basic_settings?.meta_desc
    const canonical = metaData.basic_settings?.canonical_tag

    const keywords = metaData.keyword_settings.map((key) => key.keyword)

    const ogTitle = metaData.og_settings?.og_title
    const ogDescription = metaData.og_settings?.og_desc
    const ogSitename = metaData.og_settings?.og_sitename
    const ogImage = metaData.og_settings?.og_image
    const ogImageType = metaData.og_settings?.og_image_type
    const ogImageWidth = metaData.og_settings?.og_image_width
    const ogImageHeight = metaData.og_settings?.og_image_height
    const ogLocale = metaData.og_settings?.og_locale
    const ogURL = metaData.og_settings?.og_url
    const ogType = metaData.og_settings?.og_type
    const ogId = metaData.og_settings?.og_id
    const ogAudio = metaData.og_settings?.og_audio
    const ogProfile = metaData.og_settings?.og_profile
    const ogVideo = metaData.og_settings?.og_video
    const ogVideoSecureURL = metaData.og_settings?.og_video_secure_url
    const ogVideoType = metaData.og_settings?.og_video_type
    const ogVideoWidth = metaData.og_settings?.og_video_width
    const ogVideoHeight = metaData.og_settings?.og_video_height
    const ogDeterminer = metaData.og_settings?.og_determiner

    const fbAppId = metaData.og_settings?.fb_app_id
    const fbAuthor = metaData.og_settings?.fb_og_article_author
    const fbPublishTime = metaData.og_settings?.fb_og_article_published_time
    const fbModifyTime = metaData.og_settings?.fb_og_article_modified_time

    const twitterCard = metaData.og_settings?.twitter_card
    const twitterCreator = metaData.og_settings?.twitter_creator
    const twitterImage = metaData.og_settings?.twitter_image
    const twitterURL = metaData.og_settings?.twitter_url
    const twitterSite = metaData.og_settings?.twitter_site
    const twitterDescription = metaData.og_settings?.twitter_desc
    const twitterTitle = metaData.og_settings?.twitter_title

	toast.configure();

	async function Merchant() {
		var T = localStorage.getItem("tokenn");

		var myHeaders = new Headers();
		myHeaders.append("Cookie", "_lang=en");

		var formdata = new FormData();
		formdata.append("token", T);

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
			redirect: 'follow'
		};

		fetch(urls.UserProfile + "?merchant_keys=" + MK, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.code === 1) {
					setMerchant(result.details.data)
				} else {
					alert("Not logged in");
				}
			})
	}

	async function Loadcart() {
		var T = localStorage.getItem("tokenn");

		if (T) {
			const myHeaders = new Headers();
			myHeaders.append("Cookie", "_lang=en");

			const formdata = new FormData();
			formdata.append("device_id", T);
			formdata.append("token", T);

			const requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			};

			try {
				const response = await fetch(`${urls.LoadCart}?merchant_keys=${MK}`, requestOptions);
				const result = await response.json();

				if (result.code === 1) {
					return result.details.data.item; // Return server cart data
				} else {
					return []; // Return an empty array if cart is empty
				}
			} catch (error) {
				console.error('Loadcart Fetch Error:', error); // Log fetch error if any
				return []; // Return an empty array on fetch error
			}
		} else {
			const raw = localStorage.getItem('cart');
			const cartFetchedFromLocalStorage = JSON.parse(raw);
			return cartFetchedFromLocalStorage || []; // Return local cart data or empty array
		}
	}

	const mergeCarts = (localCart, serverCart) => {

		const mergedCart = [...serverCart];

		localCart.forEach(localItem => {

			const existingItemIndex = mergedCart.findIndex(serverItem =>
				serverItem?.item_id === localItem.item_id && serverItem?.size_id === localItem.size_id
			);

			if (existingItemIndex !== -1) {
				// item exists on server cart also
				mergedCart[existingItemIndex].qty += localItem.qty;
				mergedCart[existingItemIndex].size_words += localItem.size_words;
			} else {
				// item does not exist on server cart
				mergedCart.push(localItem);
			}
		});
		return mergedCart;
	};

	const updateServerCart = async (mergedCart) => {

		const T = localStorage.getItem("tokenn");
		const myHeaders = new Headers();
		myHeaders.append("Cookie", "_lang=en");

		const formdata = new FormData();
		
		const cartData = mergedCart.map(item => ({
			"item_id": item.item_id,
			"price": item.price,
			"qty": item.qty,
			"size_words": item.size_words,
		}));
		formdata.append('items', JSON.stringify(cartData));

			
		const requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
			redirect: 'follow'
		};

		const response = await fetch(`${urls.Add_Delete_Item}?merchant_keys=${MK}&device_id=${T}&device_platform=web&transaction_type=pickup&lang=en&token=${T}`, requestOptions);
		const result = await response.json();
		return result; 	//result has correct detail of item added
	};


//local and server data sync issue fixed 


	const handleLogin = async () => {
		const localCart = JSON.parse(localStorage.getItem('cart')) || [];

		const serverCart = await Loadcart(); // Get server cart data

		const mergedCart = mergeCarts(localCart, serverCart); // Merge local and server carts
		
		await updateServerCart(mergedCart); // Update the server with the merged cart data

		// Update the local state with the merged cart data
		localStorage.setItem('cart', JSON.stringify(mergedCart));
	};

	async function login() {
		var myHeaders = new Headers();
		myHeaders.append("Cookie", "_lang=en");

		var formdata = new FormData();
		formdata.append("username", username);
		formdata.append("password", password);

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
			redirect: 'follow'
		};

		let result = await fetch(urls.Login + "?merchant_keys=" + MK, requestOptions);
		result = await result.json();
		localStorage.setItem("tokenn", result.details.token);

		if (result.code == 1) {
			await handleLogin(); // Handle cart merging after login
			localStorage.removeItem("cart");
			Merchant();
			history.push('/');
		}
		else {
			toast.error(result.msg, {
				position: "top-center",
				autoClose: 400
			});
			history.push("/login")
		}
	}

	return (
		<>
			<Helmet>
			<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
				<title>{pageTitle}</title>
				<link rel="icon" href="/favicon.svg" />
				<link rel="canonical" href={canonical} />
				<meta name="description" content={metaDescription}></meta>
				<meta name="keywords" content={keywords}></meta>
				<meta name="basic-id" content={basicId}></meta>
				<meta name="mobile-view" content={mobileView}></meta>

				{/* og meta tags */}
				<meta property="og:id" content={ogId}></meta>
				<meta property="og:site_name" content={ogSitename}></meta>
				<meta property="og:title" content={ogTitle}></meta>
				<meta property="og:description" content={ogDescription}></meta>
				<meta property="og:url" content={ogURL}></meta>
				<meta property="og:image" content={ogImage}></meta>
				<meta property="og:image:type" content={ogImageType} />
				<meta property="og:image:width" content={ogImageWidth} />
				<meta property="og:image:height" content={ogImageHeight} />
				<meta property="og:type" content={ogType} />
				<meta property="fb:app_id" content={fbAppId} />
				<meta property="og:profile" content={ogProfile} />
				<meta property="og:locale" content={ogLocale} />
				<meta property="og:audio" content={ogAudio} />
				<meta property="og:video:secure:url" content={ogVideoSecureURL} />
				<meta property="og:video:type" content={ogVideoType} />
				<meta property="og:video:width" content={ogVideoWidth} />
				<meta property="og:video:height" content={ogVideoHeight} />
				<meta property="og:determiner" content={ogDeterminer} />
				<meta property="og:video" content={ogVideo} />

				{/* Twitter meta tags */}
				<meta name="twitter:card" content={twitterCard} />
				<meta name="twitter:site" content={twitterSite} />
				<meta name="twitter:title" content={twitterTitle} />
				<meta name="twitter:description" content={twitterDescription} />
				<meta name="twitter:image" content={twitterImage} />
				<meta name="twitter:url" content={twitterURL} />
				<meta name="twitter:creator" content={twitterCreator} />

				{/* Facebook Open Graph Specific Meta Tags (if needed) */}
				<meta property="og:app_id" content={fbAppId} />
				<meta property="og:article:author" content={fbAuthor} />
				<meta property="og:article:published_time" content={fbPublishTime} />
				<meta property="og:article:modified_time" content={fbModifyTime} />

				{metaData?.header_settings &&
metaData.header_settings.map(header => (
<meta
key={header.header_id}
name={`header-${header.header_type}`}
content={header.header_content}
/>
))}
			</Helmet>
			<Container fluid className='bg-white'>
				<Row>
					<Col md={4} lg={6}>
						<div>
							<img alt={restaurantinfo.merchant_name} className="login12" src={restaurantinfo.merchant_image} ></img>
						</div>
					</Col>
					<Col md={8} lg={6}>
						<div className="login d-flex align-items-center py-5">
							<Container>
								<Row>
									<Col md={9} lg={8} className="mx-auto pl-5 pr-5">
										<h3 className="login-heading mb-4">Welcome back!</h3>
										<Form>
											<div className="form-label-group">
												<Form.Control type="text" onChange={(e) => setUsername(e.target.value)} id="inputEmail" placeholder="Username" onKeyPress={handleKeyPress} />
												<Form.Label htmlFor="inputEmail">Email address / Mobile</Form.Label>
											</div>
											<div className="form-label-group">
												<Form.Control type={passwordShown ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} id="inputPassword" placeholder="Password" onKeyPress={handleKeyPress} />
												<Form.Label htmlFor="inputPassword">Password
													{passwordShown ?
														<div className='cursorPoint'><i className="icofont-eye-blocked float-right" onClick={togglePassword}></i></div> : <div className='cursorPoint'><i className="icofont-eye float-right" onClick={togglePassword}></i></div>}
												</Form.Label>
											</div>
											<div>
												<p className='mb-3 btn-link text-right'><Link className='text-right' to={'/forgot'}>Forgot password</Link></p></div>
											<Link to="/" type='submit' className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" onClick={login}>Sign in</Link>

											<div className="text-center pt-3">
												Don’t have an account? <Link className="font-weight-bold" to="/register">Sign Up</Link>
											</div>

											<hr className="my-4" />
											<p className="text-center">LOGIN WITH</p>
											<div className="row">
												<div className="col pr-2">
													<Button className="btn pl-1 pr-1 btn-lg btn-google font-weight-normal text-white btn-block text-uppercase" type="submit"><FontAwesome icon="google" className="mr-2" /> Google</Button>
												</div>
												<div className="col pl-2">
													<Button className="btn pl-1 pr-1 btn-lg btn-facebook font-weight-normal text-white btn-block text-uppercase" type="button"><FontAwesome icon="facebook" className="mr-2" /> Facebook</Button>
												</div>
											</div>
										</Form>
									</Col>
								</Row>
							</Container>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Login;
