//Copyright by Chaafo pvt ltd
//Detailmodal is designed to show item details when clicked on any item name

import React, { useState, useContext, useEffect } from "react";
import { Modal, Image, Button } from "react-bootstrap";
import { urls } from "../../URLs";

import Icofont from "react-icofont";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalContext_ from "../../../GlobalContext";
import { GlobalContext } from "../../../components/GlobalState";

function DetailModalAnlook(props) {
  const { MK } = useContext(GlobalContext_);

    const { anlookCart, setAnlookCart } = useContext(GlobalContext);
  const [itemdetail, setItemdetail] = useState("");
const params = new URLSearchParams(window.location.search);
var T = localStorage.getItem("tokenn");


const [anlookOrderData, setAnlookOrderData] = useState([]);
const [error, setError] = useState(null);

const [cart, setCart] = useState([]);
const [Price, setPrice] = useState([]);


  //Function to show item details using item id.
  useEffect(() => {
    const itemd = () => {
      if (props.show) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("item_id", id);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };

        fetch(urls.ItemDetails + "?merchant_keys=" + MK, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            setItemdetail(result.details?.data);
            setPrice(result.details?.data?.prices);
          });
      }
    };

    itemd();
  }, [props.show, MK]);

  useEffect(() => {
    if (Price.length === 1 && (props.cart.find(item => item.item_id === itemdetail?.item_id)?.quantity || 0) === 0) {
      const singlePrice = Price[0];
      addtocart(singlePrice.size_id, itemdetail.dish_list, itemdetail?.item_name, singlePrice.price, 1, singlePrice.size, itemdetail?.item_id);
    }
  }, [Price]);

  var id = localStorage.getItem("id");
  
  toast.configure();

  // funtion to decrease item quantity
  const DecreasecartItem = async (size_id, title, price, qty, size, item_id) => {
    
    const itemName= `${title} ( ${size} )`
    const orderId = params.get("orderid");
    const userId = params.get("userid");
    const mobile = params.get("mobile");
    const name = params.get("name");
    const raw = JSON.stringify({
      userid: userId,
      currency: "INR",
      custMobile: mobile,
      custName: name,
      orderId: orderId,
      items: [
        {
          productId: item_id,
          sizeId: size_id,
          productName: itemName,
          quantity: qty,
          itemPrice: price,
        },
      ],
    });

    try {
      const response = await fetch(
        "https://payin.shreedevs.com/api/Order/removeFromCart",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
          body: raw,
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      fetchOrderData();

      // Check if the item quantity is now 0
    const currentItem = Price.length==1 && (props.cart.find((item)=>item.size_id==Price[0].size_id && item.item_id== itemdetail?.item_id)?.quantity)
if(currentItem && currentItem==1){
    props.onHide();
}
      if (props.refresh) {
        props.refresh();
      }
      
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addtocart = async (size_id, icon, title, price, qty, size, item_id) => {
    const itemName= title + '(' + size + ')'
    const orderId = params.get("orderid");
    const userId = params.get("userid");
    const mobile = params.get("mobile");
    const name = params.get("name");
    const raw = JSON.stringify({
      userid: userId,
      currency: "INR",
      custMobile: mobile,
      custName: name,
      orderId: orderId,
      items: [
        {
          productId: item_id,
          sizeId: size_id,
          actualName: title,
          productName: itemName,
          quantity: qty,
          itemPrice: price,
          itemImage: icon[0],
          sizeWords: size,
        },
      ],
    });

    try {
      const response = await fetch(
        "https://payin.shreedevs.com/api/Order/addToCart",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
          body: raw,
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      if (props.refresh) {
        props.refresh();
      }
      // setAnlookOrderData(data.response[0].items);
    } catch (err) {
      // setError(err.message);
    }
  };

  useEffect(() => {
    if (props.show) {
      fetchOrderData();
    }
  }, []);

  const fetchOrderData = async () => {
    try {
      const response = await fetch(
        "https://payin.shreedevs.com/api/Order/getReceiptPrintDataV2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ orderid: params.get("orderid") }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setAnlookOrderData(data.response[0].items);
      setAnlookCart(data.response[0].items);
      setCart(data.response[0].items);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Modal show={props.show} onHide={props.onHide} centered className="d-flex justify-content-center align-items-center">
      <Modal.Header closeButton={true} className="gap-2 d-flex">
        <img src={itemdetail?.dish_list} alt='' loading="lazy" style={{height: '1.5rem'}}/>
        <Modal.Title as="h5" id="add-address">
            {itemdetail?.item_name}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Image
          style={{ width: "245px", height: "200px" }}
          src={itemdetail?.photo}
          alt={itemdetail?.item_id}
          loading="lazy"
        />
        {Price?.map((price, index) => (
          
          <div className="list-card-body p-2 d-flex justify-content-between align-items-centert mt-1" style={{border: '1px solid lightgrey', borderRadius: '5px'}}>
        {price.size ? (
          <>
            <div className="text-gray mb-0 d-flex flex-column">
              {price.discount_price > 0 ? (
                <div className="mb-0 text-muted">
                  <del>
                  ₹{price.price}{" "} </del>₹{price.price}{" "}
                  
                </div>
              ) : (
                <div className="text-gray time mb-0 text-muted">
                  ₹{price.price}
                </div>
              )}
                  <p className="mb-0">{price.size}</p>
            </div>
            <div>
              {(props.cart.find((item)=>item.size_id==price?.size_id && item.item_id== itemdetail?.item_id)?.quantity) > 0 ? (
                
                <span className="count-number float-right">
                  <Button
                    variant="outline-secondary"
                    onClick={()=>DecreasecartItem(price.size_id, itemdetail?.item_name, price.price, 1, price.size, itemdetail?.item_id)}
                    className="btn-sm left dec"
                  >
                    {" "}
                    <Icofont icon="minus" />{" "}
                  </Button>
                  <input
                    className="count-number-input p-0"
                    type="text"
                    value={(props.cart.find((item)=>item.size_id==price.size_id && item.item_id== itemdetail?.item_id)?.quantity) || 0}
                    readOnly
                  />
                  <Button
                    variant="outline-secondary "
                    onClick={()=>addtocart(price.size_id, itemdetail.dish_list , itemdetail?.item_name, price.price, 1, price.size, itemdetail?.item_id)}
                    className="btn-sm right inc"
                  >
                    {" "}
                    <Icofont icon="icofont-plus" />{" "}
                  </Button>
                </span>
              ) : (
                <Button
                  variant="outline-secondary"
                  onClick={()=>addtocart(price.size_id, itemdetail.dish_list , itemdetail?.item_name, price.price, 1, price.size, itemdetail?.item_id)}
                  size="sm"
                >
                  ADD
                </Button>
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
        ))}
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(DetailModalAnlook);
