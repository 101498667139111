import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {  Form, InputGroup, Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CheckoutItem from './CheckoutItem';
import Icofont from 'react-icofont';
import Homeoffers from './homeoffers';
import 'react-toastify/dist/ReactToastify.css';
import GlobalContext_ from '../../GlobalContext';
import PageTitle from './PageTitle';
import './OrderSummaryCard.css';
import { CardColors, SiteColors } from '../../constants/colorTheme';

const OrderSummaryCard = (
    {
        state,
        key1,
        setKey1,
        getQty,
        VOUCHER,
        REMOVE,
        setPoints,
        Points,
        removepoints,
        ServiceList1,
        service,
        setTransactiontype,
        ServiceList,
        setShowEditModal,
        setSetDelivery,
        pageName,
        cart,
        total,
        clearCart,
        T,
        voucher,
        subTotal,
        scrollTotop,
        Mobile,
        setMobile,
    }) => {
        
    const { restaurantinfo } = useContext(GlobalContext_);

    return (
        <div className='scrollToTop'>
            {pageName === 'checkout' ?
                <div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item" style={{background: CardColors.primary}}>
                    <div className="d-flex mb-4 osahan-cart-item-profile">
                        <Image fluid className="mr-3 rounded-pill" alt={restaurantinfo?.merchant_image} src={restaurantinfo?.merchant_image} />
                        <div className="d-flex flex-column">
                            <h6 className="mb-1 text-white">{restaurantinfo?.merchant_name}
                            </h6>
                            <p className="mb-0 text-white"><Icofont icon="location-pin" />{restaurantinfo?.address}</p>
                        </div>
                    </div>
                    {
                        state.cart === null ? (<div className="text-center bg-white rounded shadow-sm mb-2">
                            {state.empty}
                        </div>) :
                            state.cart.length > 0 ?
                                <div>
                                    {(state.cart.map((cart, Index) =>
                                   
                                        <div className="bg-white rounded shadow-sm mb-2" key={Index}>
                                            {console.log(cart, 'find icon_dish in cart')}
                                            <CheckoutItem
                                                itemName={cart.item_name}
                                                price={Number(cart.discounted_price)}
                                                vegNonvegTag={cart.icon_dish}
                                                size_words={cart.size_words || ''}
                                                discounted_price={cart.normal_price}
                                                priceUnit="₹ "
                                                id={cart.item_id}
                                                qty={Number(cart.qty)}
                                                show={true}
                                                minValue={0}
                                                maxValue={12}
                                                index={Index}
                                                getValue={getQty}
                                                category_id={1}
                                            />
                                        </div>
                                    ))}

                                    <div className="mb-2 bg-white rounded p-2 clearfix">
                                        <Link to="/offers" className="btn btn-block btn-lg btn-outline-success mb-2" >Use Coupon
                                            <Icofont icon="long-arrow-right" /></Link>
                                        <InputGroup className="input-group-sm mb-2">
                                            {state.total?.less_voucher === 0 ?
                                                <Form.Control type="text" value={key1} onChange={(e) => setKey1(e.target.value)} placeholder="Enter promo code" /> :
                                                <Form.Control type="text" value={state.total.voucher_name} placeholder="Enter promo code" />
                                            }
                                            <InputGroup.Append>
                                                {state.total?.less_voucher === 0 ?
                                                    <Button variant="success" type="button" id="button-addon2" onClick={VOUCHER}><Icofont icon="sale-discount" /> APPLY</Button> :
                                                    <Button variant="primary" type="button" onClick={REMOVE} id="button-addon2"> REMOVE VOUCHER</Button>
                                                }
                                            </InputGroup.Append>
                                        </InputGroup>

                                        <InputGroup className="input-group-sm mb-2">
                                            {state.total.pts_redeem_amt === 0 ?
                                                <Form.Control type="text" onChange={(e) => setPoints(e.target.value)} placeholder="Enter points" />
                                                : <Form.Control type="text" value={state.total.pts_redeem_amt} onChange={(e) => setPoints(e.target.value)} placeholder="Enter points" />
                                            }
                                            <InputGroup.Append>
                                                {state.total.pts_redeem_amt === 0 ?
                                                    <Button variant="success" type="button" id="button-addon2" onClick={Points}> REDEEM POINTS</Button>
                                                    : <Button variant="primary" type="button" id="button-addon2" onClick={removepoints}> REMOVE POINTS</Button>
                                                }
                                            </InputGroup.Append>
                                        </InputGroup>

                                        <InputGroup className="mb-0">
                                        </InputGroup>
                                    </div>
                                    {state.total ?
                                        <div>
                                            <div className="mb-2 bg-white rounded p-2 clearfix">
                                                {state.total?.less_voucher === 0 ? "" :
                                                    <div>
                                                        <p className="mb-1">Less Voucher <span className="float-right text-dark">{state.total?.less_voucher}</span>  </p>
                                                    </div>}
                                                <p className="mb-1">Item Total <span className="float-right text-dark"><span className="text-danger">₹{Math.round(Math.abs(state.total?.subtotal))}</span></span></p>
                                                <p className="mb-1">Restaurant Charges <span className="float-right text-dark">₹{Math.round(state.total.taxable_total)}</span></p>
                                                <p className="mb-1">Points Redeemed <span className="float-right text-dark">{state.total?.pts_redeem_amt}</span></p>
                                                <p className="mb-1">Delivery Fee
                                                    <OverlayTrigger
                                                        key="top"
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                Total discount breakup
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className="text-info ml-1">
                                                            <Icofont icon="info-circle" />
                                                        </span>
                                                    </OverlayTrigger>
                                                    <span className="float-right text-dark">₹{state.total.delivery_charges}</span>
                                                </p>
                                                <p className="mb-1 text-success">Total Discount
                                                    <span className="float-right text-success">₹{state.total.total_discount}</span>
                                                </p>
                                                <hr />
                                                <h6 className="font-weight-bold mb-0">TO PAY  <span className="float-right">₹{Math.abs(state.total.total)}</span></h6>
                                            </div>
                                            <div>
                                                <Form.Control as="select" className='mb-4 mt-4' onClick={ServiceList1} onChange={(e) => setTransactiontype(e.target.value)} >
                                                    <option value="" disabled selected>Select transaction type</option>
                                                    <option onClick={ServiceList}>{service.Delivery}</option>
                                                    <option onClick={ServiceList}>{service.Pickup}</option>
                                                    <option onClick={ServiceList}>{service.Dinein}</option>
                                                </Form.Control>
                                            </div>
                                            {state.Pay.transaction_type === "Delivery" ?
                                                state.count.street ? <div className="mb-2 bg-white rounded p-2 clearfix">Deliver here:<br></br><p style={{ color: 'black' }}>{state.count.street} {state.count.city} {state.count.state} {state.count.zipcode}</p>
                                                    <Link onClick={() => setShowEditModal(true)} className="btn btn-primary btn-block ">Change address
                                                    </Link></div>
                                                    : <Link onClick={() => setSetDelivery(true)} className="btn btn-primary btn-block btn-lg">Set Delivery Address
                                                    </Link> :
                                             ""}
                                            <div className="pt-2"></div>
                                            <div className="alert alert-success" role="alert">
                                                You have saved <strong>{state.total.total_discount}</strong> on the bill
                                            </div>
                                            <div className="pt-2"></div>

                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>

                                : <div className="text-center bg-white rounded shadow-sm mb-2">
                                    {state.empty}
                                </div>
                    }

                </div> :
            //   _________________________home_page______________________________________________________
              <div>

                    {voucher.free_delivery ?
                        <PageTitle
                        title={voucher.title || ''}
                            subTitle={voucher.free_delivery}
                        /> : " "}
                    <div className=''>
                    {(voucher.voucher?.map(Voucher =>
                        <Homeoffers
                            vouchertitle={Voucher.voucher_title}
                            voucherdescription={Voucher.voucher_description}
                            Discount={Voucher.Discount}
                            vouchername={Voucher.voucher_name}
                        />
                    ))}
                    </div>
                    {
                        state.cart === null ? (<div className="text-center bg-white rounded shadow-sm mb-2">
                            {state.empty}
                        </div>) :
                            state.cart.length > 0 ?
                                <div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item" style={{background: CardColors.primary}} >
                                    <div>
                                        <h5 className="mb-1 text-white">Your Order
                                        </h5>
                                        {state.count === null ? null :
                                            <p className="mb-4 text-white">{state.count.cart_count} Items</p>}
                                        {(state.cart?.map((cart, Index) =>
                                      
                                            <div className="bg-white rounded shadow-sm mb-2" key={Index}>
                                                {T ?
                                                    <CheckoutItem
                                                        itemName={cart.item_name}
                                                        price={Number(cart.discounted_price)}
                                                        discounted_price={cart.normal_price}
                                                        priceUnit="₹ "
                                                        vegNonvegTag={cart.icon_dish}
                                                        size_words={cart.size_words || ''}
                                                        id={Number(cart.item_id)}
                                                        qty={Number(cart.qty)}
                                                        show={true}
                                                        minValue={0}
                                                        maxValue={12}
                                                        index={Index}
                                                        getValue={getQty}
                                                        size_id={cart.size_id}
                                                        category_id={1}
                                                        // size_words={cart.find}
                                                    /> :
                                                    <CheckoutItem
                                                        itemName={cart.item_name}
                                                        price={cart.price}
                                                        discounted_price={cart.normal_price}
                                                        priceUnit="₹ "
                                                        vegNonvegTag={cart.icon_dish}
                                                        id={cart.item_id}
                                                        qty={Number(cart.qty)}
                                                        show={true}
                                                        size_words={cart.size_words || ''}
                                                        minValue={0}
                                                        maxValue={12}
                                                        index={Index}
                                                        getValue={getQty}
                                                        size_id={cart.size_id}
                                                        category_id={1}
                                                    />}
                                            </div>
                                        ))}
                                        <div>
                                            <Link className="btn btn-secondary btn-block btn-lg" onClick={clearCart}>Clear Cart
                                            </Link>
                                        </div>
                                        <div className="mb-2 bg-white rounded p-2 clearfix">
                                            <Image fluid className="float-left" src="/img/wallet-icon.png" alt='wallet icon' loading="lazy" />

                                            <div>
                                                {T ?
                                                    <h6 className="font-weight-bold text-right mb-2" style={{color: CardColors.textPrimary}}>Subtotal : <span >₹{Math.round(Math.abs(state.total?.subtotal))}</span></h6> :
                                                    <h6 className="font-weight-bold text-right mb-2" style={{color: CardColors.textPrimary}}>Subtotal : <span >₹{subTotal(cart)}</span></h6>
                                                }
                                                {T ?
                                                    <p className="text-black mb-0 text-right">Less Voucher :  {state.total?.less_voucher}  </p> : ''}
                                            </div>

                                        </div>
                                        {!T ?
                                            <Link to="/MobileVerification" style={{background: CardColors.buttonPrimary}} className="btn btn-block btn-lg text-white" onClick={scrollTotop}>Checkout
                                                <Icofont icon="long-arrow-right" /></Link>
                                            :
                                            <Link to="/checkout" style={{background: CardColors.buttonPrimary}} className="btn btn-block btn-lg text-white" onClick={scrollTotop}>Checkout
                                                <Icofont icon="long-arrow-right" /></Link>
                                        }
                                        <div className="pt-2"></div>
                                    </div>
                                    <div className="text-center bg-white rounded shadow-sm mb-2">
                                        {state.empty}
                                    </div>
                                </div>
                            : 
                            <div></div>
                    }

                </div>
            }
        </div>
    )
}

export default OrderSummaryCard;